import React, { useContext } from "react";
import { AppContext } from "../App";
import { Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getFilterCookies } from "../utils/cookies";
import {
  setUpdatesOne,
  setUpdatesTwo,
  updateAppLoading,
  updateDuplicateData,
  updateDuplicateSalesForceData,
  updateNPSTagging,
} from "../redux/actions/appActions";
import useAggregation from "./useAggregations";
import useValidation from "./useValidation";
import { pages } from "./usePage";
import useTableUtils from "./useTableUtils";

export default function useTagging(page) {
  const {
    toolbarForm,
    messageApi,
    rowsToBeUpdated,
    rowsToBeUpdatedForTableTwo,
    salesforceRowsTobeUpdated,
    setSalesforceRowsTobeUpdated,
    setRowsToBeUpdated,
    setRowsToBeUpdatedForTableTwo,
  } = useContext(AppContext);
  const duration = 5; //toast message duration

  const { data, duplicateData, updatesOne, updatesTwo } = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const { aggregateSAPDataOnLoad, aggregateSalesforceDataOnLoad } = useAggregation(page);
  const { validateTagging } = useValidation();
  const { getDataFromStore } = useTableUtils();
  const [_, _duplicateData] = getDataFromStore(page);

  const warn = (resOne, resTwo) => {
    const [percentError, percentZero, criteriaError, NPSYearError, npsTagError] = resOne;
    const [percentErrorTwo, percentZeroTwo, criteriaErrorTwo, NPSYearErrorTwo, npsTagErrorTwo] = resTwo;
    Modal.warning({
      title: "Error",
      content: (
        <>
          {(percentError || percentErrorTwo) && <p>* New NPS % + Renew NPS % should not exceed 100%.</p>}
          {(criteriaError || criteriaErrorTwo) && <p>* NPS Criteria is required.</p>}
          {(NPSYearError || NPSYearErrorTwo) && <p>* NPS Year is required.</p>}
          {(percentZero || percentZeroTwo) && <p>* New NPS % + Renew NPS % should not be 0.</p>}
          {(npsTagError || npsTagErrorTwo) && <p>* The same material cannot be tagged as NPS and Application for the same year.</p>}
        </>
      ),
    });
  };

  //updatedRows is an array of rowIndexes of updated rows
  // updates is an object with key as rowIndex and value as updated values
  const merge = (updatedRows, updates) => {
    if (updatedRows && updatedRows.length) {
      return updatedRows.map((row) => updates[row]);
    }
    return [];
  };
  const mergeUpdates = () => {
    let newRows = [];
    newRows = merge(rowsToBeUpdated, updatesOne);
    newRows = [...newRows, ...merge(rowsToBeUpdatedForTableTwo, updatesTwo)];
    newRows = [...newRows, ...merge(salesforceRowsTobeUpdated, updatesTwo)];
    newRows = newRows.filter((row) => row?.HashKey); // picks nonempty rows
    return newRows;
  };

  const getIndividualRowsForAggregatedRows = React.useCallback((aggregatedRows) => {
    return aggregatedRows.reduce((acc, row) => {
      const rows = row.Hashes.map((hash) => ({
        ...row,
        HashKey: hash,
      }));
      return acc.concat(rows);
    }, []);
  }, []);

  const taggingHelper = () => {
    const resOne = validateTagging(rowsToBeUpdated, updatesOne, 1);
    const resTwo = validateTagging(rowsToBeUpdatedForTableTwo, updatesTwo, 2);
    if (resOne.includes(true) || resTwo.includes(true)) {
      warn(resOne, resTwo);
      return;
    }
    let rows = mergeUpdates();
    if (rows.length === 0) {
      messageApi.open({
        key: "update",
        type: "warning",
        content: "Please select a row or Enter new values to update",
        duration,
      });
      return;
    }
    messageApi.open({
      key: "update",
      type: "loading",
      content: "Updating rows, Please Hang on...",
      duration: 1200,
    });
    rows = getIndividualRowsForAggregatedRows(rows);
    return rows;
  };

  const submitTagging = async () => {
    const filterCookies = getFilterCookies();
    try {
      let rows = taggingHelper();
      if (!rows) return;
      const res = await updateNPSTagging({ hier2: filterCookies.hier2, rows });
      messageApi.open({
        key: "update",
        type: "success",
        content: "Rows Updated.",
        duration,
      });
      let _duplicateData = JSON.parse(JSON.stringify(duplicateData));
      res.forEach((row) => {
        const idx = _duplicateData.findIndex((_row) => _row.HashKey === row.HashKey);
        if (idx > -1) {
          _duplicateData.splice(idx, 1, row);
        }
      });
      dispatch(updateDuplicateData(_duplicateData));
      aggregateSAPDataOnLoad(_duplicateData, true);
      dispatch(setUpdatesOne({}));
      dispatch(setUpdatesTwo({}));

      // newRows is the updated rows
      const deselectRows = (newRows, table) => {
        newRows.forEach((row) => {
          const idx = data.findIndex((_row) => _row.HashKey === row.HashKey);
          if (idx !== -1) {
            const checkbox = document.getElementById(`check-row-${table}-${idx}`);
            if (checkbox) checkbox.checked = false;
          }
        });
      };

      deselectRows(res, 1);
      deselectRows(res, 2);

      setRowsToBeUpdated([]);
      setRowsToBeUpdatedForTableTwo([]);
      toolbarForm.resetFields();
      dispatch(updateAppLoading(false));
    } catch (err) {
      console.log(err);
      dispatch(updateAppLoading(false));
      messageApi.open({
        key: "update",
        type: "error",
        content: "Something went wrong, Please try again. ",
        duration,
      });
    }
  };

  const submitSalesforceTagging = async (business = pages.liveo) => {
    try {
      let rows = taggingHelper();
      if (!rows) return;
      const res = await updateNPSTagging({
        hier2: "ELECTRONICS & INDUSTRIAL",
        hier5: business,
        dataType: "salesforce",
        rows,
      });
      messageApi.open({
        key: "update",
        type: "success",
        content: "Rows Updated.",
        duration,
      });
      res.forEach((row) => {
        const idx = _duplicateData.findIndex((_row) => _row.HashKey === row.HashKey);
        if (idx > -1) {
          _duplicateData.splice(idx, 1, row);
        }
      });
      aggregateSalesforceDataOnLoad(_duplicateData, true);
      dispatch(updateDuplicateSalesForceData(_duplicateData));
      dispatch(setUpdatesTwo({}));
      setRowsToBeUpdated([]);
      rowsToBeUpdatedForTableTwo.forEach((row, index) => {
        const checkbox = document.getElementById(`check-row-2-${row}`);
        if (checkbox) checkbox.checked = false;
      });
      setRowsToBeUpdatedForTableTwo([]);
      setSalesforceRowsTobeUpdated([]);
      toolbarForm.resetFields();
    } catch (err) {
      messageApi.open({
        key: "update",
        type: "error",
        content: "Something went wrong, please try again",
        duration,
      });
    }
  };

  return { submitTagging, submitSalesforceTagging };
}

import { AutoComplete, Button, Select, TreeSelect } from "antd";
import { getMonthOptions, getSelectedGmidMonths, getYearOptions, validateYear } from "../../utils/monthUtils";
import React, { memo, useMemo, useContext } from "react";
import { AppContext } from "../../App";
import { getColumnFilterCookies, getFilterCookies } from "../../utils/cookies";
import { useDispatch, useSelector } from "react-redux";
import { CheckOutlined, DeleteOutlined } from "@ant-design/icons";
import { setAppError } from "../../redux/actions/appActions";
import useTagging from "../../hooks/useTagging";
import { updateUserSelection } from "../../redux/actions/userSelectionActions";
import useData from "../../hooks/useData";
import useFilterUtils from "../../hooks/useFilterUtils";
import useDebounce from "../../hooks/useDebounce";
import { usePage } from "../../hooks/usePage";

function DHeader({ tableRef, npsTableRef }) {
  const yearOptions = getYearOptions();
  const monthOptions = useMemo(() => getMonthOptions(), []);
  const { GMIDYearLocal, setGMIDMonthLocal, setGMIDYearLocal, messageApi } = useContext(AppContext);
  const dispatch = useDispatch();
  const { duplicateData, currentPage } = useSelector((state) => state.app);
  const { gmidMonth, salesYear, gmidYear } = useSelector((state) => state.selections);
  const gmidYearOptions = getYearOptions();
  const { debounce, lastTimeout } = useDebounce();
  const page = usePage();
  const { submitTagging } = useTagging(page);
  const { clearProductFilters } = useFilterUtils(page);
  const { getData } = useData(page);

  const applyFilters = async (year, month, sales) => {
    messageApi.open({
      type: "loading",
      content: "Applying filters, please wait",
      duration: 60,
      key: "filters",
    });
    const cachedFilters = getFilterCookies();
    await getData({
      ...cachedFilters,
      gmidyear: year,
      gmidmonth: month,
      salesyear: sales,
    })
      .then((res) => {
        messageApi.open({
          key: "filters",
          type: "success",
          duration: 5,
          content: "Filters applied",
        });
      })
      .catch((err) => {
        messageApi.open({
          key: "filters",
          type: "error",
          duration: 5,
          content: err.message,
        });
        dispatch(setAppError(true));
      });
  };

  const onChangeHandler = (year = gmidYear, month = gmidMonth, sales = salesYear) => {
    if (lastTimeout) clearTimeout(lastTimeout);
    if (month.length === 0) return;
    if (sales.toString().length !== 4) return;
    if (!validateYear(year)) return;

    if (lastTimeout) clearTimeout(lastTimeout);
    debounce(() => applyFilters(year, month, sales), 1500);
  };

  return (
    <div className="flex flex-row justify-between items-center mb-3  w-[93vw] d-header">
      <span className="text-red-500 font-normal text-3xl ml-0">NPS Evaluation</span>
      <div className="flex gap-2 items-center">
        <span>Material Created Date</span>
        <AutoComplete
          status={validateYear(GMIDYearLocal) ? "" : "error"}
          style={{ width: 137, height: 30, overflow: "clip" }}
          placeholder="Year"
          options={gmidYearOptions}
          value={GMIDYearLocal}
          onChange={(year) => {
            setGMIDYearLocal(year);
            dispatch(updateUserSelection({ gmidYear: year }));
            onChangeHandler(year);
          }}
        />
        <TreeSelect
          style={{ width: 137, height: 30, overflow: "clip" }}
          placeholder="Month"
          value={gmidMonth}
          treeData={monthOptions}
          treeCheckable
          treeDefaultExpandAll
          treeIcon={true}
          onChange={(month) => {
            const _month = getSelectedGmidMonths(month);
            dispatch(updateUserSelection({ gmidMonth: _month }));
            setGMIDMonthLocal(_month);
            onChangeHandler(gmidYear, _month, salesYear);
          }}
        />
        <div className="flex gap-2 items-center">
          <span>Sales Year</span>
          <Select
            style={{ width: 100, height: 30 }}
            placeholder="Sales Year"
            options={yearOptions.slice(0, 2)}
            value={salesYear}
            onChange={(year) => {
              const gmid = year < gmidYear ? year : gmidYear;
              dispatch(updateUserSelection({ salesYear: year, gmidYear: gmid }));
              setGMIDYearLocal(gmid);
              onChangeHandler(gmid, gmidMonth, year);
            }}
          />
        </div>
      </div>
      <div className="flex flex-row gap-2">
        <Button
          onClick={() => clearProductFilters(tableRef, npsTableRef, duplicateData, currentPage)}
          type="default"
          icon={<DeleteOutlined />}
          disabled={!getColumnFilterCookies(1)}
        >
          Clear Filters
        </Button>
        <Button
          onClick={() => {
            submitTagging();
          }}
          type="default"
          icon={<CheckOutlined />}
        >
          Save
        </Button>
      </div>
    </div>
  );
}

export default memo(DHeader);

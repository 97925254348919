import { getDefaultGMidMonthYear } from "../../utils/monthUtils";
import { userSelectionType } from "../action.types";
const defaultGMID = getDefaultGMidMonthYear();

const date = new Date();

const initialState = {
  gmidMonth: [defaultGMID.month],
  gmidYear: defaultGMID.year,
  hier2: null,
  hier3: null,
  hier4: null,
  hier5: null,
  salesYear: date.getFullYear(),
  salesYearSf: date.getFullYear(),
  salesMonthSf: [defaultGMID.month],
};

export const userSelections = (state = initialState, action) => {
  switch (action.type) {
    case userSelectionType.UPDATE_USER_SELECTION:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

import { Button } from "antd";
import React, { useContext, useEffect, useState } from "react";
import SideBar from "../components/SideBar";
import DashboardBody from "../components/DashboardBody";
import { useDispatch, useSelector } from "react-redux";
import { setScrollSync } from "../utils/scrollSync";
import { AppContext } from "../App";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import ToolBar from "../components/ToolBar";
import { getFilterCookies } from "../utils/cookies";
import { setAppError, setUpdatesOne, setUpdatesTwo } from "../redux/actions/appActions";
import useData from "../hooks/useData";
import { usePage } from "../hooks/usePage";

function NPSDashboard() {
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [isToolbarOpen, setToolBarOpen] = useState(false);
  const { data, duplicateData, columns, hasAccessToApp } = useSelector((state) => state.app);
  const { isFilterDrawerOpen, setFilterDrawerOpen } = useContext(AppContext);
  const { isAuthenticated } = useSelector((state) => state.auth);
  const { gmidMonth, gmidYear, salesYear } = useSelector((state) => state.selections);
  const dispatch = useDispatch();
  const page = usePage();
  const { getData } = useData(page);

  useEffect(() => {
    setScrollSync();
  }, [data, columns]);

  useEffect(() => {
    const initialize = async () => {
      const cachedFilters = getFilterCookies();
      if (!cachedFilters) {
        setFilterDrawerOpen(isAuthenticated);
      } else if (!cachedFilters?.hier2 || !cachedFilters?.hier3 || !cachedFilters?.hier5) {
        const path = window.location.pathname;
        if (path !== "/salesforce") setFilterDrawerOpen(isAuthenticated);
      } else {
        await getData({
          gmidmonth: gmidMonth,
          gmidyear: gmidYear,
          salesyear: salesYear,
          ...cachedFilters,
        });
      }
    };
    if (!duplicateData || duplicateData?.length === 0) initialize();
  }, [isAuthenticated]);
  useEffect(() => {
    return () => {
      setFilterDrawerOpen(false);
      dispatch(setAppError(null));
      dispatch(setUpdatesOne({}));
      dispatch(setUpdatesTwo({}));
    };
  }, []);
  return (
    <div>
      {!isToolbarOpen && hasAccessToApp ? (
        <Button
          onClick={() => {
            setToolBarOpen((prevState) => !prevState);
          }}
          icon={isToolbarOpen ? <UpOutlined /> : <DownOutlined />}
          className="rotate-90 bg-gray-400 z-10 fixed top-[140px] right-0 "
          style={{
            ...(isToolbarOpen ? { marginRight: 473, borderBottom: "none" } : { marginRight: -25 }),
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
          }}
        >
          Tools
        </Button>
      ) : null}
      {hasAccessToApp ? (
        <div className="flex flex-col">
          <div style={{ marginTop: -200 }}>
            {!isDrawerOpen ? (
              <Button
                onClick={() => {
                  setFilterDrawerOpen((prevState) => !prevState);
                }}
                className="rotate-90 mt-14 bg-white z-10 fixed top-36"
                style={{
                  ...(isFilterDrawerOpen ? { marginLeft: 462, borderBottom: "none" } : { marginLeft: -17 }),
                  marginTop: -38,
                  borderBottomLeftRadius: 0,
                  borderBottomRightRadius: 0,
                }}
              >
                Filters
              </Button>
            ) : null}
          </div>
          <div style={{ marginTop: -200 }}>
            {!isFilterDrawerOpen ? (
              <Button
                onClick={() => {
                  setDrawerOpen((prevState) => !prevState);
                }}
                className="rotate-90 mt-14 bg-white z-10 fixed top-36"
                style={{
                  ...(isDrawerOpen
                    ? {
                        marginLeft: 510,
                        marginTop: -15,
                        borderBottom: "none",
                      }
                    : { marginLeft: -39 }),
                  borderBottomLeftRadius: 0,
                  borderBottomRightRadius: 0,
                }}
              >
                Column view
              </Button>
            ) : null}
          </div>
        </div>
      ) : null}
      {hasAccessToApp ? (
        <>
          <SideBar isOpen={isDrawerOpen} setOpen={setDrawerOpen} />
          <ToolBar isOpen={isToolbarOpen} setOpen={setToolBarOpen} />
        </>
      ) : null}
      <DashboardBody />
    </div>
  );
}

export default NPSDashboard;

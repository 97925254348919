import { useDispatch, useSelector } from "react-redux";
import { COLUMN } from "../utils/columns";
import { pages } from "./usePage";
import { updateApplicationRow, updateSalesforceRow, updateSAPRow } from "../redux/actions/appActions";
import { useCallback, useContext } from "react";
import { AppContext } from "../App";
import { setUpdatesOne, setUpdatesTwo } from "../redux/actions/appActions";

export default function useToolbar() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { toolbarForm, setRowsToBeUpdated, setRowsToBeUpdatedForTableTwo, setSalesforceRowsTobeUpdated } = useContext(AppContext);

  const selectAllHandler = useCallback(
    (length, setRowsToBeUpdated) => {
      const values = toolbarForm.getFieldsValue();
      const { tagType } = values;
      const checked = values["selectAll"];
      let checkboxes = null;
      if (tagType === "nps") {
        checkboxes = document.getElementsByClassName("check-row-1");
      } else {
        checkboxes = document.getElementsByClassName("check-row-2");
      }
      if (checkboxes && checkboxes.length)
        for (let i = 0; i < checkboxes.length; i++) {
          checkboxes[i].checked = checked;
        }
      if (checked) {
        const temp = Array(length)
          .fill()
          .map((_, i) => i);
        setRowsToBeUpdated(temp);
      } else {
        setRowsToBeUpdated([]);
      }
    },
    [toolbarForm]
  );

  const tagChangeHandler = useCallback(
    (setRowsToBeUpdated) => {
      const values = toolbarForm.getFieldsValue();
      const { tagType } = values;
      let checkboxes = [];
      toolbarForm.setFieldValue("selectAll", false);
      toolbarForm.resetFields();
      if (tagType === "nps") {
        toolbarForm.setFieldValue("tagType", "nps");
        checkboxes = document.getElementsByClassName("check-row-2");
      } else {
        toolbarForm.setFieldValue("tagType", "application");
        checkboxes = document.getElementsByClassName("check-row-1");
      }
      if (checkboxes && checkboxes.length)
        for (let i = 0; i < checkboxes.length; i++) {
          checkboxes[i].checked = false;
        }
      setRowsToBeUpdated([]);
    },
    [toolbarForm]
  );

  const fillFormValues = useCallback(
    (target, updatedRows, updates, data, dataType = pages.SAP, selectedTagType) => {
      const toolbarValues = toolbarForm.getFieldsValue();
      if (updatedRows && updatedRows.length) {
        updatedRows.forEach((row) => {
          if (!updates[`${row}`]) {
            updates[`${row}`] = {
              HashKey: data[row].HashKey,
              Hashes: data[row].Hashes,
              TaggedBy: user.fullname,
              QualifyAsApplication: data[row].QualifyAsApplication,
              QualifyAsNPS: data[row].QualifyAsNPS,
              NPSPercentageNew: data[row].NPSPercentageNew,
              NPSPercentageRenew: data[row].NPSPercentageRenew,
              NPSYear: data[row].NPSYear,
              NPSCriteria: data[row].NPSCriteria,
              SustnAdvgedSales: data[row].SustnAdvgedSales,
              SustnAdvgedSalesPercentage: data[row].SustnAdvgedSalesPercentage,
            };
          }
          // setting default values if nps or application is set to yes on the toolbar.
          if ((target.id === COLUMN.QualifyAsApplication || target.id === COLUMN.QualifyAsNPS) && toolbarValues[target.id] === "YES") {
            if (target.id === COLUMN.QualifyAsNPS) updates[`${row}`].QualifyAsApplication = "NO";
            if (target.id === COLUMN.QualifyAsApplication) updates[`${row}`].QualifyAsNPS = "NO";
            updates[`${row}`].NPSPercentageNew = updates[`${row}`]?.NPSPercentageNew || data[row].NPSPercentageNew || 0;
            updates[`${row}`].NPSPercentageRenew = updates[`${row}`]?.NPSPercentageRenew || data[row].NPSPercentageRenew || 0;
            updates[`${row}`].NPSYear = updates[`${row}`]?.NPSYear || data[row].GMIDYear;
            updates[`${row}`].NPSCriteria = updates[`${row}`]?.NPSCriteria || data[row].NPSCriteria;
          }
          updates[row][target.id] = toolbarValues[target.id] || null;
          if (dataType === pages.salesforce) {
            dispatch(updateSalesforceRow(updates[row], row));
          } else if (selectedTagType === "nps") dispatch(updateSAPRow(updates[row], row));
          else dispatch(updateApplicationRow(updates[row], row));
        });
      }
      return updates;
    },
    [dispatch, toolbarForm, user?.fullname]
  );

  const resetToolbarAndUpdates = useCallback(() => {
    toolbarForm.resetFields();
    dispatch(setUpdatesOne({}));
    dispatch(setUpdatesTwo({}));
    setRowsToBeUpdated([]);
    setRowsToBeUpdatedForTableTwo([]);
    setSalesforceRowsTobeUpdated([]);
  }, [dispatch, setRowsToBeUpdated, setRowsToBeUpdatedForTableTwo, setSalesforceRowsTobeUpdated, toolbarForm]);

  return { selectAllHandler, tagChangeHandler, fillFormValues, resetToolbarAndUpdates };
}

import { useSelector } from "react-redux";
import { pages } from "./usePage";

export default function useTableUtils() {
  const { data, applicationData, duplicateData, salesforceData, duplicateSalesforceData } = useSelector((state) => state.app);

  const getDataFromStore = (page, table) => {
    if (page === pages.salesforce) {
      return [salesforceData, duplicateSalesforceData];
    } else {
      if (table === 1) return [data, duplicateData];
      else return [applicationData, duplicateData];
    }
  };
  return { getDataFromStore };
}

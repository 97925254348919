import { useDispatch } from "react-redux";
import { resetColumnFilters, setColumnFilterCookies } from "../utils/cookies";
import { setUpdatesOne, setUpdatesTwo, updateApplicationData, updateData, updateSalesForceData } from "../redux/actions/appActions";
import useAggregation from "./useAggregations";

export default function useFilterUtils(page) {
  const dispatch = useDispatch();
  const { aggregateProductData, aggregateApplicationData, aggregateSalesforceDataOnLoad } = useAggregation(page);

  const onFilterChange = async (newData, filterConfiguration, setCurrentPage, table) => {
    setColumnFilterCookies(filterConfiguration, table);
    if (page === "SAP") {
      if (table === 1) {
        const aggregated = await aggregateProductData(newData);
        dispatch(updateData(aggregated));
      } else {
        const aggregated = await aggregateApplicationData(newData);
        dispatch(updateApplicationData(aggregated));
      }
    } else {
      const aggregated = await aggregateSalesforceDataOnLoad(newData);
      dispatch(updateSalesForceData(aggregated));
    }
    setCurrentPage(1);
    dispatch(setUpdatesOne({}));
    dispatch(setUpdatesTwo({}));
  };

  const clearFilterHandler = (tableRef, npsTableRef, aggregated) => {
    if (aggregated) {
      if (tableRef) tableRef.reset(aggregated);
      if (npsTableRef) npsTableRef.reset(aggregated);
    }
  };

  const clearProductFilters = async (tableRef, npsTableRef, dupData, currentPage, table = 1) => {
    resetColumnFilters(table, page);
    if (tableRef) tableRef.reset([]);
    if (npsTableRef) npsTableRef.reset([]);
    const aggregated = await aggregateProductData(dupData);
    if (aggregated) clearFilterHandler(tableRef, npsTableRef, aggregated, currentPage, table);
  };

  const clearApplicationFilters = async (tableRef, npsTableRef, duplicateData, currentPage, table = 2) => {
    resetColumnFilters(table, page);
    if (tableRef) tableRef.reset([]);
    if (npsTableRef) npsTableRef.reset([]);
    const aggregated = await aggregateApplicationData(duplicateData);
    if (aggregated) clearFilterHandler(tableRef, npsTableRef, aggregated, currentPage, table);
  };

  const clearSalesforceFilters = async (tableRef, npsTableRef, duplicateData, currentPage, table = 2) => {
    resetColumnFilters(table, page);
    if (tableRef) tableRef.reset([]);
    if (npsTableRef) npsTableRef.reset([]);
    const aggregated = await aggregateSalesforceDataOnLoad(duplicateData);
    if (aggregated) clearFilterHandler(tableRef, npsTableRef, aggregated, currentPage, table);
  };

  return { onFilterChange, clearProductFilters, clearApplicationFilters, clearSalesforceFilters };
}

import { CloseOutlined } from "@ant-design/icons";
import { Checkbox, ConfigProvider, Drawer, Form, InputNumber, Radio, Select } from "antd";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { AppContext } from "../App";
import { useDispatch, useSelector } from "react-redux";
import { setUpdatesOne, setUpdatesTwo } from "../redux/actions/appActions";
import { pages, usePage } from "../hooks/usePage";
import { getNpsYearOptions } from "../utils/monthUtils";
import useToolbar from "../hooks/useToolbar";

function ToolBar({ isOpen, setOpen }) {
  const {
    setRowsToBeUpdated,
    setRowsToBeUpdatedForTableTwo,
    setSalesforceRowsTobeUpdated,
    toolbarForm,
    rowsToBeUpdated,
    rowsToBeUpdatedForTableTwo,
    salesforceRowsTobeUpdated,
  } = useContext(AppContext);

  const { hasAccessToApp, updatesOne, updatesTwo } = useSelector((state) => state.app);
  const page = usePage();
  const salesYear = useSelector((state) => (page === pages.SAP ? state.selections.salesYear : state.selections.salesYearSf));
  const dispatch = useDispatch();
  const [selectedTagType, setSelectedTagType] = useState(page === pages.salesforce ? "application" : "nps");
  const data = useSelector((state) => {
    if (page === "salesforce") return state.app.salesforceData;
    else if (selectedTagType === "nps") return state.app.data;
    else return state.app.applicationData;
  });
  const { roles } = useSelector((state) => state.auth);
  const isProdViewer = roles && roles.includes("NPS_PROD_VIEWER");
  const { selectAllHandler, tagChangeHandler, fillFormValues } = useToolbar();
  const npsYearOptions = getNpsYearOptions(salesYear);

  const onChangeHandler = useCallback(
    (target) => {
      if (rowsToBeUpdated && rowsToBeUpdated.length) {
        const _updates = fillFormValues(target, rowsToBeUpdated, updatesOne, data, pages.SAP, selectedTagType);
        dispatch(setUpdatesOne(_updates));
      }
      if (rowsToBeUpdatedForTableTwo && rowsToBeUpdatedForTableTwo.length) {
        const _updates = fillFormValues(target, rowsToBeUpdatedForTableTwo, updatesTwo, data, pages.SAP, selectedTagType);
        dispatch(setUpdatesTwo(_updates));
      }
      if (salesforceRowsTobeUpdated && salesforceRowsTobeUpdated.length) {
        const _updates = fillFormValues(target, salesforceRowsTobeUpdated, updatesTwo, data, pages.salesforce, selectedTagType);
        dispatch(setUpdatesTwo(_updates));
      }
    },
    [
      data,
      dispatch,
      fillFormValues,
      rowsToBeUpdated,
      rowsToBeUpdatedForTableTwo,
      salesforceRowsTobeUpdated,
      selectedTagType,
      updatesOne,
      updatesTwo,
    ]
  );

  const selectedRowCount = useMemo(
    () => Math.max(rowsToBeUpdated.length, rowsToBeUpdatedForTableTwo.length) || salesforceRowsTobeUpdated.length,
    [rowsToBeUpdated?.length, rowsToBeUpdatedForTableTwo?.length, salesforceRowsTobeUpdated?.length]
  );

  return (
    <>
      {hasAccessToApp ? (
        <ConfigProvider
          theme={{
            components: {
              Drawer: {
                colorBgElevated: "#D9D9D9",
                colorBgMask: "rgba(0,0,0,0)",
              },
            },
          }}
        >
          <Drawer
            placement="right"
            rootClassName="tool-drawer"
            open={isOpen}
            onOk={() => setOpen(false)}
            onCancel={() => setOpen(false)}
            onClose={() => setOpen(false)}
            closeable={false}
            closeIcon={null}
            maskClosable={false}
            destroyOnClose={true}
            width={760}
            height={100}
            mask={false}
          >
            <Form
              form={toolbarForm}
              layout="vertical"
              onBlur={(e) => {
                onChangeHandler(e.target);
              }}
              onSelect={(e) => {
                onChangeHandler(e.target);
              }}
              disabled={isProdViewer}
            >
              <div className="flex flex-row justify-start items-start">
                <div
                  className="toolbar-close-btn flex flex-row items-center justify-center"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  <h4>
                    Tools&nbsp; <CloseOutlined />
                  </h4>
                </div>
                <div className="flex flex-row justify-start gap-x-5 w-full">
                  <div className="flex flex-col">
                    <Form.Item name="selectAll" style={{ marginBottom: 0 }} valuePropName="checked">
                      <Checkbox
                        title="Select All"
                        indeterminate={data?.length !== selectedRowCount && selectedRowCount !== 0}
                        checked={selectedRowCount === data?.length}
                        onChange={() => {
                          if (page === pages.salesforce) {
                            selectAllHandler(data?.length, setSalesforceRowsTobeUpdated);
                          } else if (toolbarForm.getFieldValue("tagType") === "nps") selectAllHandler(data?.length, setRowsToBeUpdated);
                          else selectAllHandler(data?.length, setRowsToBeUpdatedForTableTwo);
                        }}
                      >
                        {!toolbarForm.getFieldValue("selectAll") ? "Select All" : "Deselect All"} ({selectedRowCount || 0})
                      </Checkbox>
                    </Form.Item>
                    <Form.Item name="tagType" initialValue={selectedTagType}>
                      <Radio.Group
                        initialValue={selectedTagType}
                        value={selectedTagType}
                        onChange={({ target: { value } }) => {
                          setSelectedTagType(value);
                          if (value === "nps") tagChangeHandler(setRowsToBeUpdatedForTableTwo);
                          else tagChangeHandler(setRowsToBeUpdated);
                        }}
                      >
                        <div className="flex flex-col">
                          {page !== pages.salesforce && <Radio value="nps">NPS</Radio>}
                          {(page === pages.SAP || page === pages.salesforce) && <Radio value="application">Application</Radio>}
                        </div>
                      </Radio.Group>
                    </Form.Item>
                  </div>
                  <div className="grid grid-cols-4 xl:grid-cols-4 2xl:grid-cols-5 items-end grid-rows-2 gap-x-2">
                    <Form.Item
                      name={toolbarForm.getFieldValue("tagType") === "application" ? "QualifyAsApplication" : "QualifyAsNPS"}
                      label={toolbarForm.getFieldValue("tagType") === "application" ? "Qualify as ADI:" : "Qualify as NPS:"}
                    >
                      <Select
                        allowClear
                        style={{
                          width: 110,
                          height: 30,
                        }}
                        placeholder={toolbarForm.getFieldValue("tagType") === "application" ? "Application" : "NPS"}
                        options={[
                          { label: "YES", value: "YES" },
                          { label: "NO", value: "NO" },
                          { label: "TBD", value: "TBD" },
                        ]}
                      />
                    </Form.Item>
                    <Form.Item name="NPSPercentageNew" label="NPS New %:">
                      <InputNumber
                        type="number"
                        style={{
                          width: 110,
                          height: 30,
                        }}
                        placeholder="NPS New %"
                        min={0}
                        max={100}
                      />
                    </Form.Item>
                    <Form.Item name="NPSPercentageRenew" label="NPS Renew %:">
                      <InputNumber
                        type="number"
                        style={{
                          width: 110,
                          height: 30,
                        }}
                        placeholder="NPS New %"
                        min={0}
                        max={100}
                      />
                    </Form.Item>
                    <Form.Item name="NPSCriteria" label="NPS Criteria:">
                      <Select
                        allowClear
                        style={{
                          width: 110,
                          height: 30,
                        }}
                        placeholder="NPS Criteria"
                        options={[
                          { label: "2", value: "2" },
                          { label: "3", value: "3" },
                          { label: "4", value: "4" },
                          { label: "5", value: "5" },
                          { label: "6", value: "6" },
                        ]}
                      />
                    </Form.Item>
                    <Form.Item name="SustnAdvgedSales" label="Sust Adv Sales:">
                      <Select
                        allowClear
                        style={{
                          width: 110,
                          height: 30,
                        }}
                        placeholder="Sust Adv Sales"
                        options={[
                          { label: "YES", value: "YES" },
                          { label: "NO", value: "NO" },
                        ]}
                      />
                    </Form.Item>
                    <Form.Item name="SustnAdvgedSalesPercentage" label="% Sust Adv Sales:">
                      <InputNumber
                        style={{
                          width: 110,
                          height: 30,
                        }}
                        placeholder="% Sust Adv Sales"
                        min={0}
                        max={100}
                      />
                    </Form.Item>
                    <Form.Item name="NPSYear" label="NPS Year/Intro Yr :">
                      <Select
                        allowClear
                        style={{
                          width: 110,
                          height: 30,
                        }}
                        placeholder="Intro/NPS year"
                        options={npsYearOptions}
                      />
                    </Form.Item>
                  </div>
                </div>
              </div>
            </Form>
          </Drawer>
        </ConfigProvider>
      ) : null}
    </>
  );
}

export default ToolBar;
